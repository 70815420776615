@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./styles/mixins.scss";

@import "./styles/fonts.scss";

::-moz-selection {
  background: rgba(170, 170, 170, 0.3);
}

::selection {
  background: rgba(170, 170, 170, 0.3);
}

$green: #32c267;
$headingBlack: #2d2d2d;

.bgBody {
  height: 100%;
}

.homeBg {
  z-index: -1;
  &:after {
    content: "";
    background-size: cover;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.1; /* Here is your opacity */
  }
}

.page {
  left: 15px;
  right: 15px;
}

.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition:
    opacity 300ms,
    transform 300ms;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition:
    opacity 300ms,
    transform 300ms;
}

.pryTer {
  p {
    margin-bottom: 20px;
  }
}

.cookieContainer {
  border: 1px solid #000;
  background-color: #333 !important;
  margin: 0 0 20px 20px;
  border-radius: 5px;
  max-width: calc(100% - 40px);
  color: #fff !important;
  align-items: center !important;
  font-size: small;
  @media (min-width: 1280px) {
    max-width: 50%;
  }
}

.cookieAcceptButton {
  border-radius: 5px !important;
  color: #ffffff !important;
  background-color: $green !important;
}

.cookieDeclineButton {
  border-radius: 5px !important;
  color: #ffffff !important;
  background-color: #dc2626 !important;
}

.slide-up {
  -webkit-animation: slide-up 0.5s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: slide-up 0.5s cubic-bezier(0.65, 0, 0.35, 1) both;
}

@-webkit-keyframes slide-up {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.linksDefault {
  &:hover {
    text-decoration: underline;
  }
}

.caseStudies {
  transition: all 0.4s ease-in-out;
  @extend .slide-up;
  @include delay(animation, 15, 0.25s);
}

#slide {
  position: absolute;
  right: 50px;
  img {
    position: absolute;
    max-width: 820px;
  }
}

.slide-img {
  opacity: 0;
  transition: opacity 2s;
}

.show {
  opacity: 1;
}
