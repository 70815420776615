@import "./fonts.scss";
@import "./mixins.scss";

.heroText {
  overflow: hidden;
  div {
    @extend .HelFontBlack;
    @media (min-width: 768px) {
      line-height: 4rem;
    }
    -webkit-animation-delay: 0;
    animation-delay: 0;
    @include delay(animation, 2, 0.05s);
  }
}

.serviceSection {
  .serviceBox {
    width: 43%;
    height: 150px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 15px;
    position: relative;
    transition: ease all 0.5s;
    &:hover {
      background: #333;
      border: 1px solid #333;
      .serviceBoxText {
        transition: ease all 0.5s;
        filter: invert(1);
      }
      .serviceBoxShape {
        img {
          filter: invert(0.8);
        }
      }
    }
    @media (min-width: 768px) {
      width: 200px;
    }
    &:not(:last-child) {
      margin: 0 20px 20px 0;
      @media (min-width: 768px) {
        margin-right: 20px;
      }
    }
    .serviceBoxText {
      @extend .HelFontBold;
    }
    .serviceBoxShape {
      position: absolute;
      bottom: 15px;
    }
    @include delay(animation, 5, 0.05s);
  }
}

.experienceBox {
  &:not(:last-child) {
    margin-right: 50px;
  }
  .experienceBoxCount {
    @extend .HelFontBlack;
    font-size: 30px;
    line-height: 0.8;
  }
  .experienceBoxDetail {
    @extend .HelFontBold;
    color: #666666;
    font-size: 15px;
    line-height: 1;
    padding-top: 10px;
  }
  @include delay(animation, 5, 0.05s);
}

.bgShapeSection {
  position: relative;
  width: 50%;
  height: 100%;
  .bgShape {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    top: -730px;
    right: -470px;
    transform: rotate(252deg);
    @media (min-width: 768px) {
      top: -490px;
      right: -500px;
      transform: rotate(225deg);
    }
    @media (max-width: 1280px) {
      top: -290px;
    }
    &.bgShapeBlack {
      background: #000;
      // box-shadow: 2px -10px 50px #d0daf9;
    }
    &.bgShapeGray {
      background: #151515;
      top: -460px;
      right: -550px;
      @media (min-width: 768px) {
        top: -400px;
        right: -550px;
      }
      @media (max-width: 1280px) {
        top: -200px;
      }
    }
    &.heroSubPageImage {
      top: 0;
      right: 0;
      bottom: 80px;
      width: 630px;
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s;
      @media (min-width: 1536px) {
        width: 40%;
      }
      height: auto;
      transform: rotate(0deg);
      img {
        max-width: 100%;
        filter: grayscale(0.6);
      }
      &.ai {
        width: 550px;
      }
    }
  }
}
