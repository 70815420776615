@font-face {
  font-family: "HNBL";
  font-style: normal;
  font-weight: normal;
  src:
    local("HNBL"),
    url("../assets/fonts/HelveticaNeueLTPro-BlkCn.woff") format("woff");
}

@font-face {
  font-family: "HNBD";
  font-style: normal;
  font-weight: normal;
  src:
    local("HNBD"),
    url("../assets/fonts/HelveticaNeueLTPro-BdCn.woff") format("woff");
}

@font-face {
  font-family: "MP";
  font-style: normal;
  font-weight: normal;
  src:
    local("MP"),
    url("../assets/fonts/MusticaproSemibold-2OG5o.woff") format("woff");
}

@font-face {
  font-family: "KGRegular";
  font-style: normal;
  font-weight: normal;
  src:
    local("KGRegular"),
    url("../assets/fonts/Konkret Grotesk Pro Regular.woff") format("woff");
}

@font-face {
  font-family: "KGItalic";
  font-style: normal;
  font-weight: normal;
  src:
    local("KGItalic"),
    url("../assets/fonts/Konkret Grotesk Pro Italic.woff") format("woff");
}

@font-face {
  font-family: "KGThin";
  font-style: normal;
  font-weight: normal;
  src:
    local("KGThin"),
    url("../assets/fonts/Konkret Grotesk Pro Thin.woff") format("woff");
}

@font-face {
  font-family: "KGThin Italic";
  font-style: normal;
  font-weight: normal;
  src:
    local("KGThin Italic"),
    url("../assets/fonts/Konkret Grotesk Pro Thin Italic.woff") format("woff");
}

@font-face {
  font-family: "KGExtraLight";
  font-style: normal;
  font-weight: normal;
  src:
    local("KGExtraLight"),
    url("../assets/fonts/Konkret Grotesk Pro ExtraLight.woff") format("woff");
}

@font-face {
  font-family: "KGExtraLight Italic";
  font-style: normal;
  font-weight: normal;
  src:
    local("KGExtraLight Italic"),
    url("../assets/fonts/Konkret Grotesk Pro ExtraLight Italic.woff") format("woff");
}

@font-face {
  font-family: "KGLight";
  font-style: normal;
  font-weight: normal;
  src:
    local("KGLight"),
    url("../assets/fonts/Konkret Grotesk Pro Light.woff") format("woff");
}

@font-face {
  font-family: "KGLight Italic";
  font-style: normal;
  font-weight: normal;
  src:
    local("KGLight Italic"),
    url("../assets/fonts/Konkret Grotesk Pro Light Italic.woff") format("woff");
}

@font-face {
  font-family: "KGMedium";
  font-style: normal;
  font-weight: normal;
  src:
    local("KGMedium"),
    url("../assets/fonts/Konkret Grotesk Pro Medium.woff") format("woff");
}

@font-face {
  font-family: "KGMedium Italic";
  font-style: normal;
  font-weight: normal;
  src:
    local("KGMedium Italic"),
    url("../assets/fonts/Konkret Grotesk Pro Medium Italic.woff") format("woff");
}

@font-face {
  font-family: "KGBold";
  font-style: normal;
  font-weight: normal;
  src:
    local("KGBold"),
    url("../assets/fonts/Konkret Grotesk Pro Bold.woff") format("woff");
}

@font-face {
  font-family: "KGBold Italic";
  font-style: normal;
  font-weight: normal;
  src:
    local("KGBold Italic"),
    url("../assets/fonts/Konkret Grotesk Pro Bold Italic.woff") format("woff");
}

@font-face {
  font-family: "KGExtraBold";
  font-style: normal;
  font-weight: normal;
  src:
    local("KGExtraBold"),
    url("../assets/fonts/Konkret Grotesk Pro ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "KGExtraBold Italic";
  font-style: normal;
  font-weight: normal;
  src:
    local("KGExtraBold Italic"),
    url("../assets/fonts/Konkret Grotesk Pro ExtraBold Italic.woff") format("woff");
}

@font-face {
  font-family: "KGBlack";
  font-style: normal;
  font-weight: normal;
  src:
    local("KGBlack"),
    url("../assets/fonts/Konkret Grotesk Pro Black.woff") format("woff");
}

@font-face {
  font-family: "KGBlack Italic";
  font-style: normal;
  font-weight: normal;
  src:
    local("KGBlack Italic"),
    url("../assets/fonts/Konkret Grotesk Pro Black Italic.woff") format("woff");
}

.HelFontBlack {
  font-family:
    "KGBold",
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji";
}

.HelFontBold {
  font-family:
    "KGMedium",
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji";
}
