@import "./fonts.scss";
@import "./mixins.scss";

.folksRed {
  background: linear-gradient(to right, #f50f12, #063c89);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  &.links {
    font-weight: 500;
    font-weight: 500;
    font-size: 1rem;
  }
}
.folksTitle {
  background: #09203f;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  @extend .HelFontBlack;
}
.folksDesc {
  background: #09203f;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  @extend .HelFontBlack;
}

.portTitle {
  @extend .HelFontBlack;
}

.consultButton {
  background: linear-gradient(to right, #f50f12, #063c89);
  background-position: 100% 0;
  transition: ease 0.5s all;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-size: 300% 100%;
  &:hover {
    background-position: 0% 0;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  img {
    width: 18px;
  }
}

.menuHover {
  transition: ease 0.2s all;
  &:hover {
    background: linear-gradient(to right, #f50f12, #063c89);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 500;
    font-size: 1rem;
  }
}

.footer {
  z-index: 1;
  position: relative;
  .foot-right,
  .foot-left {
    a {
      position: relative;
      &:hover {
        text-decoration: underline;
      }
      &:not(:last-child) {
        &:after {
          content: "/";
          padding: 0 10px;
        }
      }
    }
  }
}

.navigationPane {
  position: fixed;
  right: -30%;
  z-index: 998;
  top: 0;
  color: #000;
  background: #000000;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 18% 100%);
  width: 100%;
  height: 100%;
  padding: 50px;
  transition: 300ms right cubic-bezier(0.77, 0, 0.175, 1);
  @media (min-width: 768px) {
    width: 50%;
  }
  @media (min-width: 1024px) {
    width: 30%;
  }
  &.closed {
    right: -100%;
  }
  &.opened {
    right: 0;
  }
  .closeIco {
    position: absolute;
    right: 20px;
    top: 20px;
    svg {
      path {
        stroke: #000;
      }
    }
  }
  .navPaneList {
    transition: ease all 0.5s;
    margin-bottom: 10px;
    padding: 5px 10px;
    text-align: left;
    @extend .HelFontBold;
    color: #f4f8fa;
    &.active {
      color: #537895;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
  &.contact {
    padding: 30px 0 0 20px;
    width: 100%;
    clip-path: none;
    background: #f4f8fa;
    box-shadow: 0 0 90px transparent;
    transition: ease all 0.5s;
    &.opened {
      box-shadow: 0 0 90px #000000;
    }
    @media (min-width: 768px) {
      width: 70%;
    }
    @media (min-width: 1024px) {
      width: 40%;
    }
    .contactForm {
      width: 100%;
      height: 100%;
    }
  }
}

.serviceSecBox {
  position: relative;
  margin: 10px 0;
  width: 100%;
  @include delay(animation, 7, 0.05s);
  @media (min-width: 768px) {
    width: 300px;
  }
  .serviceSecBoxIcon {
    background: #ffffff;
    border-radius: 5px;
    width: 6rem;
    transition: ease all 0.5s;
    margin-bottom: 20px;
  }
  .serviceBoxContentWrapper {
    background: #ffffff;
    border-radius: 5px;
    padding: 15px;
    transition: ease all 0.5s;
  }
  &:nth-child(even) {
    margin-left: 0;
    @media (min-width: 768px) {
      margin-left: 20px;
    }
  }
}

.clientSecs {
  img {
    filter: grayscale(1);
    transition: ease all 0.5s;
    position: absolute;
    z-index: 0;
    top: -2px;
    left: 0px;
    &:hover {
      filter: grayscale(0);
    }
  }
}

.brandingWrapper {
  display: none;
}

@for $i from 1 to 20 {
  .delay-#{$i} {
    animation-delay: $i * 0.25s;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
